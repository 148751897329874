@font-face {
  font-family: 'Gilmer-Regular';
  src: url('assets/fonts/gilmer-regular.eot');
  src: url('assets/fonts/gilmer-regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gilmer-regular.woff2') format('woff2'),
  url('assets/fonts/gilmer-regular.woff') format('woff'),
  url('assets/fonts/gilmer-regular.ttf') format('truetype'),
  url('assets/fonts/gilmer-regular.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilmer-Medium';
  src: url('assets/fonts/gilmer-medium.eot');
  src: url('assets/fonts/gilmer-medium.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gilmer-medium.woff2') format('woff2'),
  url('assets/fonts/gilmer-medium.woff') format('woff'),
  url('assets/fonts/gilmer-medium.ttf') format('truetype'),
  url('assets/fonts/gilmer-medium.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilmer-Heavy';
  src: url('assets/fonts/gilmer-heavy.eot');
  src: url('assets/fonts/gilmer-heavy.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gilmer-heavy.woff2') format('woff2'),
  url('assets/fonts/gilmer-heavy.woff') format('woff'),
  url('assets/fonts/gilmer-heavy.ttf') format('truetype'),
  url('assets/fonts/gilmer-heavy.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

$header-font-family: 'Gilmer-Heavy', Helvetica, Arial, sans-serif;
$body-font-family: 'Gilmer-Regular', Helvetica, Arial, sans-serif;

$header-lineheight: 1.4;
$header-margin-bottom: 0;

$header-styles: (
  'small': (
    'h1': ('font-size': 24, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h2': ('font-size': 20, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h3': ('font-size': 19, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h4': ('font-size': 24, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h5': ('font-size': 17, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h6': ('font-size': 16, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom)
  ),
  'medium': (
    'h1': ('font-size': 48, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h2': ('font-size': 40, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h3': ('font-size': 31, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h4': ('font-size': 24, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h5': ('font-size': 20, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h6': ('font-size': 16, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom)
  )
);


$text-12-size: 0.75rem; // 14px @ 16px rem
$text-14-size: 0.875rem; // 14px @ 16px rem
$text-16-size: 1rem; // 20px @ 16px rem
$text-18-size: 1.125rem; // 20px @ 16px rem
$text-20-size: 1.25rem; // 20px @ 16px rem
$text-22-size: 1.375rem; // 22px @ 16px rem
$text-24-size: 1.5rem; // 24px @ 16px rem
$text-28-size: 1.75rem; // 28px @ 16px rem
$text-48-size: 3.0rem; // 48px @ 16px rem

$single-column-breakpoint: 900px;
$below-single-column-breakpoint: 899.99999px;

$min-canvas-width: 640px; // corresponds to Foundation medium breakpoint

$max-canvas-width: 1800px; //1440px;
$canvas-margin: 20px;
$canvas-margin-small: 8px;

$panel-spacing: 20px;
$default-padding: 16px;
$panel-padding: 20px;
$panel-padding-top: 12px;

$banner-row-gutter: $text-16-size; // space between two vertically stacked banner entries
$banner-column-gutter: $text-28-size * 2; // space between two adjacent horizontal banner entries

$gutter: 24px;

$menu-icon-spacing: 16px;
$menu-text-spacing: 20px;
$menu-underline-height: 2px;

$menu-height: 50px;
$nav-bar-height: 48px;
$footer-height: 60px; // margin 40px + 20px footer size
$chart-height-default: 350px;
$chart-large-height: 400px;

$default-icon-width: 24px;
$default-stock-symbol-width: 3.5rem;
$default-date-col-width: 5rem;
$compact-date-col-width: 4rem;
$default-list-padding: .5rem;
$list-column-spacing: 20px;
$list-tight-column-spacing: 16px;
$list-row-separator: 32px;

$primary: #006EF9;
$primary-light-1: #109CE2;
$primary-positive-blue: #009AEB;
$icon-selected-blue: #0A95E6;

$secondary: #00B896;
$secondary-light-1: #24DCC1;
$secondary-light-2: #A2F7BF;
$secondary-light-3: #CFFBDE;
$secondary-dark: #32ADBA;
$secondary-dark-2: #01a58d;

$neutral: #98A3AB;
$neutral-dark: darken($neutral, 10%);
$neutral-light-1: #C1C3CE;
$neutral-light-2: #F4F5F9;

$neutral-dark-text: #747C94;
$neutral-super-dark-text: #434856;

$negative: #F40000;
$negative-light1: #FDCCCC;
$negative-light2: lighten(#FDCCCC, 5%);

$orange-callout: #FE5A42;

$separator-line: #B8C5D0;

$form-highlight: #E6E6E6;

$highlight: #E4EBFA;
$highlight-blue: #E8F0FF;
$highlight-blue-light-1: #E5F0FF;
$highlight-blue-panel: #F9FBFF;

$selectable: #EAEEF1;

// aliases
$canvas-background: $neutral-light-2;
$disabled: $neutral;
$positive: $secondary;
$announced: $primary-light-1;
$forecasted: #D1DAE8; //$neutral;
$forecasted-text: $neutral-dark-text;
$at-fair-value: $primary-light-1;
$above-fair-value: $negative;
$alert: $negative;
$above-fair-value-background: $negative-light1;
$margin-of-safety: $secondary;
$margin-of-safety-background: $secondary-light-3;
$disabled: $neutral-light-1;

$contrast-color: rgb(41, 44, 52);
$contrast-background: transparentize($contrast-color, .02);

$icon-default-color: rgba(0, 0, 0, .60);
$switch-background: rgba(0, 0, 0, .40);


$foundation-palette: (
  primary: $primary,
  secondary: #747C94,
  success: $secondary,
  warning: #ffae00,
  alert: $negative
);

$panel-radius: 10px;

$button-font-family: $header-font-family;
$button-margin: 0 0 0 0;
$button-radius: 5px;

$input-border: 1px solid $neutral !default;

$input-background-disabled: lighten($disabled, 20%);
$input-font-size: $text-14-size;
$input-shadow: 0;
$input-radius: $button-radius;

$dropdown-border: 1px solid $separator-line;

$has-tip-cursor: default;
$tooltip-background-color: $neutral;
$tooltip-radius: $button-radius;

$switch-height-tiny: 1rem;
$switch-radius: 10px;
$switch-paddle-radius: 10px;

$section-spacing-factor: 20px;

@import '~foundation-sites/scss/foundation';
@include foundation-everything(true);

// Foundation override

.tooltip {
  font-weight: 600;
}

.has-tip {
  font-weight: inherit;
  border-bottom: 0;
}

.input-group {
  margin-bottom: 0 !important;
  min-width: 160px;
}

.switch {
  margin-bottom: .1rem;
}

// general typography

.body-text {
  font-family: $body-font-family;
  font-size: $text-14-size;

  &.large {
    font-size: $text-16-size;
  }
}

.title {
  text-align: center;
  font-weight: 600;
}

.normal {
  @extend .body-text;
  padding-top: .2rem;
  padding-bottom: .2rem;
}

.vimeo-frame {
  padding: 56.25% 0 0 0;
  position: relative;
  margin-top: .66rem;
  margin-bottom: .66rem;
}

.vimeo-player-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.imageFrame {
  padding: 3rem;
}

.subsection {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: $primary;
}

.protip {
  @extend .normal;
  color: $secondary-dark-2;
  font-weight: 600;
  margin-top: 1rem;
  border: 1px solid #01a58d;
  border-radius: 10px;
  padding: 1rem;
  background-color: #01a58d14;
}

.highlight-box {
  @extend .normal;
  font-weight: 600;
  margin-top: 1rem;
  border: 1px solid $separator-line;
  border-radius: 10px;
  padding: 1rem;
  background-color: $neutral-light-2;
}

.inline-icon {
  font-size: 18px !important;
  display: inline-flex !important;
  vertical-align: text-bottom !important;
}

li {
  @extend .body-text;
}

ul {
  padding-top: .6rem;
}

.clickable {
  cursor: pointer;
}

@import "blog";
@import "card";
@import "entry";

p {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

