@use "sass:math";

.entry-canvas {
  display: grid;
  grid-template-rows: min-content auto;
  justify-items: center;
}

.entry-header-container {
  display: grid;
  grid-template-columns: 600px minmax(600px, min-content);
  column-gap: 0;

  @include breakpoint(1200px down) {
    grid-template-columns: 600px;
  }

}

.abstract-entry-container {
  max-width: 50vw;

  @include breakpoint(1450px up) {
    max-width: 40vw;
  }

  @include breakpoint($single-column-breakpoint down) {
    max-width: 560px;
  }
}

@media print, screen and (min-width: $single-column-breakpoint) {
  .hide-for-900 {
    display: none !important;
  }
}

@media print, screen and (max-width: $below-single-column-breakpoint) {
  .show-for-900-up {
    display: none !important;
  }
}

.content-container {
  @extend .abstract-entry-container;
  display: grid;
  grid-template-columns: 1fr;

  padding-top: $default-padding * 3;
}

.bio-container {
  @extend .abstract-entry-container;
  display: grid;
  grid-template-columns: 80px auto;
  width: 100%;
  margin-top: $default-padding * 2;
  margin-bottom: $default-padding * 2;
  border-top: 1px solid $separator-line;
  border-bottom: 1px solid $separator-line;
  grid-column: auto;
  padding: $default-padding;
  align-items: center;

}

.bio-right {
  padding-left: $default-padding;
}

.entry-right-container {
  display: grid;
  grid-template-rows: .6fr .4fr;
}

.entry-title {
  display: grid;
  grid-template-rows: min-content auto;
  background-color: $neutral-light-2;
  padding: $default-padding * 3;
}

.entry-title-text {
  font-family: "Gilmer-Heavy", sans-serif;
  font-size: 3rem;
  line-height: 1.2;
}

.entry-category {
  @extend .blog-category;
  font-size: 1.4rem;
}

.entry-avatar {
  display: block;
  overflow: hidden;
  margin: 0 0 0 -6px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 20%);
}

.caption-container {
  display: grid;
  padding-left: $default-padding *3;
  grid-template-rows: min-content auto;
}

.entry-avatar-container {
  position: relative;
  top: -40px;
}

.entry-author-text {
  color: $neutral-dark-text;
  font-family: "Gilmer-Medium", sans-serif;
  position: relative;
  top: -28px;
}

.entry-bio-text {
  color: $neutral-dark-text;
  font-size: .88rem;
  padding-top: $default-padding;
}

.image-container {
  padding-top: $default-padding;
}

.image-caption {
  text-align: center;
  padding-top: $default-padding;
  padding-bottom: $default-padding * 3;
  color: $neutral-dark-text;
  font-size: .9rem;
}

.body-text {
  font-size: 1.1rem;
}

.data-table {
  @extend .hover;
  @extend .body-text;
  margin-top: $default-padding * 2;
  border-collapse: collapse;
  font-variant-numeric: tabular-nums;
  text-align: right;
  font-size: 1rem;
  border: 1px solid $separator-line;

  th {
    text-align: right;
    font-weight: 600;
  }

  tr, td, th {
    border: 1px solid $separator-line;
  }
}

.table-caption {
  text-align: center;
  font-weight: 600;
  padding-top: math.div($default-padding, 2);
  padding-bottom: $default-padding * 3;
}

.singleline {
  margin-top: 0;
  margin-bottom: 0;
}

.bullet-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.image-title {
  text-align: center;
  font-family: "Gilmer-Heavy", sans-serif;
  padding-top: $default-padding * 3;
  padding-bottom: $default-padding*2;
}
