@use "sass:math";

.cards-top-section {
  display: grid;
  //grid-area: cards-section;
  grid-template-columns:  800px;
  //justify-items: center;
  row-gap: $default-padding;
  column-gap: $default-padding;
  padding-top: $default-padding;
  padding-bottom: $default-padding * 3;
  padding-left: $default-padding;
  padding-right: $default-padding;

  &.top {
    padding-top: 0;
  }
}

.cards-section {
  display: grid;

  grid-template-columns: repeat(2, 400px);

  @include breakpoint($single-column-breakpoint down) {
    grid-template-columns:  400px;
  }

  row-gap: $default-padding;
  column-gap: $default-padding;
  padding-top: $default-padding;
  padding-bottom: $default-padding * 3;
  padding-left: $default-padding;
  padding-right: $default-padding;

  &.top {
    padding-top: 0;
  }
}

.article-card {
  display: grid;
  grid-auto-flow: row;

  grid-template-rows: min-content min-content min-content min-content min-content;
  row-gap: $default-padding;

  cursor: pointer;
  user-select: none;

  grid-template-areas: "body" "title" "description";

  padding: $default-padding;
  border-radius: $panel-radius;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    position: relative;
    top: -4px;
  }
}

.card-description {
  grid-area: description;
  font-size: $text-14-size;
  font-weight: 400;
  color: $neutral-super-dark-text;
  padding-top: 4px;

}

.card-info {
  display: grid;
  column-gap: $default-padding;
  grid-template-columns: 36px auto;
}

.card-author-img {
  padding-left: 8px;
  align-self: center;
}

.card-article-data {
  display: grid;
  grid-template-rows: min-content min-content;
  font-size: 90%;

  > div:nth-child(1) {
    font-weight: 600;
  }

  > div:nth-child(2) {
    color: $neutral-super-dark-text;
  }
}

.card-avatar {
  display: block;
  overflow: hidden;
  margin: 0 0 0 -6px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 20%);
}


