@use "sass:math";

.index-grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 50px auto;

  &.boxed {
    background-color: $highlight;
    padding: 16px;
    border-radius: $panel-radius;
  }
}

.top-nav-bar {
  display: grid;
  column-gap: $default-padding;
  justify-content: stretch;
  grid-template-columns: max-content max-content auto;
  background-image: url("./assets/menu-gradient.svg");
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;

  padding-bottom: $default-padding;
  padding-left: 5vw;
  padding-right: 5vw;

  @include breakpoint($single-column-breakpoint down) {
    padding-left: $default-padding;
    padding-right: $default-padding;
  }

  @include breakpoint(1450px up) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  padding-top: 10px;
}

.top-nav-logo {
  cursor: pointer;
}

.top-nav-title {
  color: #FFFFFF;
  font-family: "Gilmer-Medium", sans-serif;
  font-size: 1.8rem;
  padding-top: 0;
  line-height: .9;
  cursor: pointer;
}

.top-nav-pi-link {
  color: #FFFFFF;
  font-family: "Gilmer-Medium", sans-serif;
  font-size: .9rem;
  line-height: 2;
  text-align: right;

  a {
    color: #FFFFFF;
  }
}

.blog-banner {
  font-family: "Gilmer-Heavy", sans-serif;
  font-size: 4rem;
}

.blog-sub-banner {
  padding-top: math.div($default-padding, 2);
  font-size: 1.6rem;
  color: $neutral-dark-text;
}

.blog-banner-container {
  text-align: center;
  padding-top: $default-padding * 2;
}

.listing-canvas {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: $default-padding
}

.synposis {
  color: $neutral-dark-text;
}

.pi-add-box {
  margin-top: $default-padding *2;
  display: grid;
  padding: 40px 10px;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: min-content min-content min-content;
  grid-template-rows: min-content min-content min-content;
  background-color: #141414;

  position: sticky;
  top: 0;
}

.pi-add-logo-container {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  -ms-grid-columns: min-content max-content;
  grid-template-columns: min-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pi-add-heading {
  background-image: linear-gradient(95deg, #006cfa, #24dcc1);
  color: #006cfa;
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
  text-align: center;
  -webkit-background-clip: text;
  //background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pi-add-text {
  padding-top: 0;
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
}

.pi-add-button-container {
  justify-self: center;
}

.pi-add-caption-container {
  padding-top: $default-padding;
  padding-bottom: $default-padding
}

.pi-add-gradient-button {
  padding: 8px 25px 10px;
  border-radius: 25px;
  background-color: transparent;
  background-image: linear-gradient(95deg, #ec3c94, $orange-callout 89%);
  color: #fff;
  line-height: 17px;
  font-weight: 500;
  text-decoration: none;
}


.blog-category {
  display: grid;
  grid-auto-flow: column;
  column-gap: $default-padding;
  font-size: 100%;
  font-weight: 600;
  font-variant: all-small-caps;
  font-family: "Gilmer-Heavy", sans-serif;
  color: $orange-callout;
}

